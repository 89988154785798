import { createContext, ReactNode, useContext, useState } from "react";

export const LocaleContext = createContext("");

export const LocaleProvider = ({ children }: { children: ReactNode }) => {
  const [locale] = useState("de-DE");

  // dummy for extension -> retrieve real locale from server

  return (
    <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const locale = useContext(LocaleContext);
  return locale;
};
