import { Typography, Divider } from "@mui/material";

export const HomeHeader = ({ title }: { title: string }) => {
  return (
    <>
      <Typography pb={2} variant="h1" fontSize="1.75rem">
        {title}
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
    </>
  );
};
