import { Box, List, ListSubheader, styled } from "@mui/material";
import { Exercise } from "@peakconcepts/physio-types/Exercise";
import { Dispatch, SetStateAction } from "react";
import {
  DragDropContext,
  DraggableLocation,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useI18n } from "../../../../translation";
import { ExerciseListContent } from "./ExerciseListContent";

const StyledList = styled(List)<{ isDraggingOver: boolean }>`
  background: ${({ isDraggingOver }) =>
    isDraggingOver ? "lightblue" : "transparent"};
  border: 1px solid grey;
  display: flex;
  flex: 1;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const ExerciseLists = ({
  selectedExercises,
  setSelectedExercises,
  availableExercises,
  setAvailableExercises,
}: {
  selectedExercises: Exercise[] | undefined;
  setSelectedExercises: Dispatch<SetStateAction<Exercise[] | undefined>>;
  availableExercises: Exercise[] | undefined;
  setAvailableExercises: Dispatch<SetStateAction<Exercise[] | undefined>>;
}) => {
  const i18n = useI18n();
  const DROPPABLE_ID_AVAILABLE = "AVAILABLE";
  const DROPPABLE_ID_SELECTED = "SELECTED";
  const getList = (id: string) =>
    id === DROPPABLE_ID_SELECTED ? selectedExercises : availableExercises;

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    sourceList: Exercise[],
    destinationList: Exercise[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const sourceClone = Array.from(sourceList);
    const destClone = Array.from(destinationList);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: { [id: string]: Exercise[] } = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  // a little function to help us with reordering the result
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId) || [],
        source.index,
        destination.index
      );

      if (source.droppableId === DROPPABLE_ID_SELECTED) {
        setSelectedExercises(items);
      } else {
        setAvailableExercises(items);
      }
    } else {
      const result = move(
        getList(source.droppableId) || [],
        getList(destination.droppableId) || [],
        source,
        destination
      );

      setSelectedExercises(result[DROPPABLE_ID_SELECTED]);
      setAvailableExercises(result[DROPPABLE_ID_AVAILABLE]);
    }
  };

  return (
    <Box display="flex" minHeight={0} columnGap={4} sx={{ flex: "1 1 0" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {[DROPPABLE_ID_SELECTED, DROPPABLE_ID_AVAILABLE].map((droppableId) => (
          <Box key={droppableId} display="flex" flex={1} flexDirection="column">
            <ListSubheader sx={{ padding: 0 }} component="div">
              {droppableId === DROPPABLE_ID_SELECTED
                ? i18n["workout.exerciseList.selected"]
                : i18n["workout.exerciseList.available"]}
            </ListSubheader>
            <Droppable droppableId={droppableId}>
              {(provided, snapshot) => (
                <StyledList
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  <ExerciseListContent
                    items={
                      droppableId === DROPPABLE_ID_SELECTED
                        ? selectedExercises
                        : availableExercises
                    }
                  />
                  {provided.placeholder}
                </StyledList>
              )}
            </Droppable>
          </Box>
        ))}
      </DragDropContext>
    </Box>
  );
};
