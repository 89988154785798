import { User } from "firebase/auth";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { firebaseAuth } from "../config/firebase";
import { useTranslationHelpers } from "../translation/useTranslationHelpers";

export const AuthContext = createContext<User | null | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authUser, setAuthUser] = useState<User | null>();
  useEffect(() => {
    firebaseAuth.onAuthStateChanged(setAuthUser);
  }, []);

  return (
    <AuthContext.Provider value={authUser}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const authUser = useContext(AuthContext);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (authUser) {
      authUser
        .getIdTokenResult()
        .then((result) => {
          const isAdmin = !!result.claims.admin;
          // authenticated user could be also an user of the app
          // check if user is authorized to access the admin panel
          if (isAdmin) setIsAuthorized(true);
          else firebaseAuth.signOut();
        })
        .catch((error) => toast.error(getTranslatedErrorMessage(error)))
        .finally(() => setIsAuthLoading(false));
    }
    if (authUser === null) {
      setIsAuthLoading(false);
      setIsAuthorized(false);
    }
  }, [authUser]);

  return { authUser, isAuthLoading, isAuthorized };
};
