import { Box } from "@mui/material";
import { get, ref } from "firebase/database";
import { Params } from "react-router-dom";
import { HomeHeader } from "../../../components/HomeHeader";
import { firebaseDB } from "../../../config/firebase";
import { useI18n } from "../../../translation";
import { EditWorkoutData } from "./EditWorkoutData";
import { WorkoutInfoPaper } from "./WorkoutInfoPaper";

export const EditWorkout = () => {
  const i18n = useI18n();

  return (
    <>
      <HomeHeader title={i18n["workout.edit.title"]} />
      <Box display="flex" flexDirection="column" gap={4}>
        <Box display="flex" flexDirection="column" gap={4} flex={1}>
          <EditWorkoutData />
        </Box>
        <Box display="flex" flexDirection="column" gap={4} flex={1} mb={4}>
          <WorkoutInfoPaper />
        </Box>
      </Box>
    </>
  );
};

export const workoutLoader = async ({ params }: { params: Params }) => {
  const { workoutId } = params;
  const dbRef = ref(firebaseDB, `workouts/${workoutId}`);
  return get(dbRef)
    .then((snapshot) => {
      if (snapshot.exists()) return snapshot.val();
      return null;
    })
    .catch((error) => {
      console.error(error);
      throw new Response("", { status: 404 });
    });
};
