import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { HomeLayout } from "./layouts/HomeLayout/HomeLayout";
import {
  EditExercise,
  exerciseLoader,
} from "./pages/Exercises/EditExercise/EditExercise";
import { Exercises } from "./pages/Exercises/Exercises";
import { NewExercise } from "./pages/Exercises/NewExercise";
import { Login } from "./pages/Login/Login";
import { Start } from "./pages/Start/Start";
import {
  EditTrainingPlan,
  trainingPlanLoader,
} from "./pages/TrainingPlans/EditTrainingPlan/EditTrainingPlan";
import { NewTrainingPlan } from "./pages/TrainingPlans/NewTrainingPlan/NewTrainingPlan";
import { TrainingPlans } from "./pages/TrainingPlans/TrainingPlans";
import { EditUser, userLoader } from "./pages/Users/EditUser/EditUser";
import { NewUser } from "./pages/Users/NewUser/NewUser";
import { Users } from "./pages/Users/Users";
import {
  EditWorkout,
  workoutLoader,
} from "./pages/Workouts/EditWorkout/EditWorkout";
import { NewWorkout } from "./pages/Workouts/NewWorkout/NewWorkout";
import { Workouts } from "./pages/Workouts/Workouts";
import { RouterError } from "./RouterError";
import { useAuth } from "./service/AuthProvider";

export const App = () => {
  const { isAuthLoading, isAuthorized } = useAuth();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<RouterError />}>
        {isAuthorized ? (
          <Route element={<HomeLayout />} errorElement={<RouterError />}>
            <Route index element={<Start />} />
            <Route path="users" element={<Users />} />
            <Route path="users/new" element={<NewUser />} />
            <Route
              path="users/:userId"
              element={<EditUser />}
              loader={userLoader}
            />
            <Route path="trainingPlans" element={<TrainingPlans />} />
            <Route path="trainingPlans/new" element={<NewTrainingPlan />} />
            <Route
              path="trainingPlans/:userId"
              element={<EditTrainingPlan />}
              loader={trainingPlanLoader}
            />
            <Route path="workouts" element={<Workouts />} />
            <Route path="workouts/new" element={<NewWorkout />} />
            <Route
              path="workouts/:workoutId"
              element={<EditWorkout />}
              loader={workoutLoader}
            />
            <Route path="exercises" element={<Exercises />} />
            <Route path="exercises/new" element={<NewExercise />} />
            <Route
              path="exercises/:exerciseId"
              element={<EditExercise />}
              loader={exerciseLoader}
            />
          </Route>
        ) : (
          <>
            <Route index={isAuthLoading} element={<></>} />
            <Route index={!isAuthLoading} element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};
