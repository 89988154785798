import { LoadingButton } from "@mui/lab";
import { Box, Paper, TextField, Typography } from "@mui/material";
import { Exercise } from "@peakconcepts/physio-types/Exercise";
import { ref, update } from "firebase/database";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";
import { firebaseDB } from "../../../config/firebase";
import { checkExistsBy } from "../../../service/checkExistsBy";
import { useI18n } from "../../../translation";
import { useTranslationHelpers } from "../../../translation/useTranslationHelpers";

export const EditExerciseData = () => {
  const i18n = useI18n();
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const defaultValues = useLoaderData() as Exercise | undefined;
  const revalidator = useRevalidator();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    clearErrors,
    reset,
  } = useForm<Exercise>({
    reValidateMode: "onSubmit",
    defaultValues,
  });

  const updateExercise = async (data: Exercise) => {
    update(ref(firebaseDB, `exercises/${data.id}`), {
      ...data,
      name: data.name.trim(),
      updatedOn: new Date().toISOString(),
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit: SubmitHandler<Exercise> = async (data) => {
    setIsLoading(true);
    try {
      const alreadyExists = await checkExistsBy({
        prop: "name",
        path: "exercises",
        value: data.name.trim(),
      });
      const isNameChanged = defaultValues?.name.trim() !== data.name.trim();
      if (isNameChanged && alreadyExists) {
        toast.error(i18n["error.exercise.alreadyExists"]);
        return undefined;
      }
      await updateExercise(data);
      toast.success(i18n["exercise.success.updated"]);
      revalidator.revalidate();
      reset(data);
    } catch (error) {
      toast.error(getTranslatedErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => clearErrors()}
      method=""
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        p={2}
        pl={3}
        rowGap={4}
        component={Paper}
        elevation={2}
      >
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          {i18n["form.edit.subtitle"]}
        </Typography>

        <TextField
          required
          fullWidth
          label={i18n["name"]}
          {...register("name", { required: true })}
        />
        <TextField
          required
          fullWidth
          label={i18n["exercise.videoUrl"]}
          multiline
          {...register("video.url", { required: true })}
        />
        <TextField
          fullWidth
          label={i18n["exercise.category"]}
          {...register("category")}
        />
        <TextField
          fullWidth
          label={i18n["description"]}
          multiline
          {...register("description")}
        />
        <LoadingButton
          sx={{ alignSelf: "flex-start" }}
          type="submit"
          disabled={!isDirty}
          loading={isLoading}
        >
          {i18n["form.buttons.save"]}
        </LoadingButton>
      </Box>
    </form>
  );
};
