import { useLocale } from "../service/LocaleProvider";
import { useI18n } from "../translation";

export const useDateHelpers = () => {
  const i18n = useI18n();
  const userLocale = useLocale();

  const getLocalDate = (
    date: string | Date | undefined,
    config?: { onlyDate?: true }
  ) => {
    if (!date) return i18n["unknown"];
    return new Date(date).toLocaleString(
      userLocale,
      config?.onlyDate && {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }
    );
  };

  return { getLocalDate };
};
