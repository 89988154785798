import { Box } from "@mui/material";
import { useRouteError } from "react-router-dom";
import { isRouteErrorResponse } from "react-router-dom";

export const RouterError = () => {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error))
    return (
      <Box p={4}>
        <h1>Oops!</h1>
        <h2>Sorry, an unexpected error has occurred.</h2>
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </Box>
    );

  return (
    <Box p={4}>
      <div>Oops</div>
      <h2>Sorry, an unexpected error has occurred.</h2>
    </Box>
  );
};
