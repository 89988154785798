import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { ReactComponent as MediHomeSvgLogo } from "../../assets/MediHomeLogo.svg";
import { useI18n } from "../../translation";

const MediHomeLogo = styled(MediHomeSvgLogo)`
  width: 150px;
  height: 150px;
`;

export const Start = () => {
  const i18n = useI18n();

  return (
    <Box p={4} sx={{ display: "flex" }}>
      <MediHomeLogo />
      <Box
        px={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {i18n["appName"]}
        </Typography>
        <Typography variant="subtitle1">{i18n["start.welcomeBack"]}</Typography>
        <Typography variant="subtitle1">
          {i18n["start.pleaseChooseEntry"]}
        </Typography>
      </Box>
    </Box>
  );
};
