import styled from "@emotion/styled";
import { default as AccessibilityIcon } from "@mui/icons-material/Accessibility";
import { default as AssignmentIcon } from "@mui/icons-material/Assignment";
import { default as ExpandMoreIcon } from "@mui/icons-material/ExpandMore";
import { default as FitnessCenterIcon } from "@mui/icons-material/FitnessCenter";
import { default as LogoutIcon } from "@mui/icons-material/Logout";
import { default as PersonIcon } from "@mui/icons-material/Person";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as MediHomeSvgLogo } from "../../assets/MediHomeLogo.svg";
import { firebaseAuth } from "../../config/firebase";
import { useAdmin } from "../../service/AdminProvider";
import { useI18n } from "../../translation";

const DRAWER_WIDTH = 260;
const StyledDrawer = styled(Drawer)`
  width: ${DRAWER_WIDTH}px;
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: ${DRAWER_WIDTH}px;
    box-sizing: "border-box";
  }
`;

const MediHomeLogo = styled(MediHomeSvgLogo)`
  width: 35px;
  height: 35px;
`;

const StyledAccordion = styled(Accordion)`
  flex: 1;

  .MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 35px;
  height: 35px;
`;

export const HomeLayout = () => {
  const i18n = useI18n();
  const navigate = useNavigate();
  const { getName, isAdminLoading } = useAdmin();

  return (
    <Box display="flex" height="100%">
      <StyledDrawer variant="permanent" anchor="left">
        <List>
          <ListItem>
            <ListItemIcon>
              <MediHomeLogo />
            </ListItemIcon>
            <ListItemText primary={i18n["appName"].toUpperCase()} />
          </ListItem>
        </List>
        <Divider variant="middle" />
        <List>
          <ListItem>
            <StyledAccordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItemIcon sx={{ alignSelf: "center" }}>
                  <StyledAvatar />
                </ListItemIcon>
                {isAdminLoading ? (
                  <Skeleton sx={{ width: "100%" }} />
                ) : (
                  <ListItemText primary={getName()} />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <List disablePadding>
                  <ListItem button onClick={() => firebaseAuth.signOut()}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={i18n["logout"]} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </StyledAccordion>
          </ListItem>
        </List>
        <Divider variant="middle" />

        <List
          subheader={
            <ListSubheader>{i18n["drawer.title"].toUpperCase()}</ListSubheader>
          }
        >
          <ListItem button onClick={() => navigate("/users")}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={i18n["drawer.users"]} />
          </ListItem>

          <ListItem button onClick={() => navigate("/trainingPlans")}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={i18n["drawer.trainingPlans"]} />
          </ListItem>

          <ListItem button onClick={() => navigate("/workouts")}>
            <ListItemIcon>
              <FitnessCenterIcon />
            </ListItemIcon>
            <ListItemText primary={i18n["drawer.workouts"]} />
          </ListItem>

          <ListItem button onClick={() => navigate("/exercises")}>
            <ListItemIcon>
              <AccessibilityIcon />
            </ListItemIcon>
            <ListItemText primary={i18n["drawer.exercises"]} />
          </ListItem>
        </List>
      </StyledDrawer>
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
