import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { User } from '@peakconcepts/physio-types/User'
import { Controller, useFormContext } from 'react-hook-form'
import { useI18n } from '../../../translation'

export const UserTypeSelection = () => {
  const i18n = useI18n()
  const { control } = useFormContext<User>()

  return (
    <FormControl fullWidth>
      <InputLabel required id="user-type-selection-label">
        {i18n['user.type']}
      </InputLabel>
      <Controller
        render={({ field: { value, onChange, ref } }) => (
          <Select
            required
            labelId="user-type-selection-label"
            id="user-type-selection--select"
            value={value || ''}
            label={i18n['user.type']}
            onChange={event => onChange(event.target.value)}
            inputRef={ref}>
            <MenuItem value="standard">{i18n['user.type.standard']}</MenuItem>
            <MenuItem value="private">{i18n['user.type.private']}</MenuItem>
          </Select>
        )}
        rules={{ required: true }}
        name="type"
        control={control}
      />
    </FormControl>
  )
}
