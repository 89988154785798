import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  deDE,
  GridColumns,
  GridRowsProp,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { Workout } from "@peakconcepts/physio-types/Workout";
import { get, ref } from "firebase/database";
import { intersectionWith } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { CustomToolbar } from "../../../components/grid/CustomToolbar";
import { firebaseDB } from "../../../config/firebase";
import { useLocale } from "../../../service/LocaleProvider";
import { useI18n } from "../../../translation";
import { daysOfWeekShort } from "../../../utils/types/DayOfWeek";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomTrainingPlan } from "../NewTrainingPlan/NewTrainingPlan";
import { useTranslationHelpers } from "../../../translation/useTranslationHelpers";

export const WorkoutSelection = ({ disabled }: { disabled?: boolean }) => {
  const i18n = useI18n();
  const { getTranslatedErrorMessage, getTranslatedDayOfWeek } =
    useTranslationHelpers();
  const userLocale = useLocale();
  const { control } = useFormContext<CustomTrainingPlan>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [rows, setRows] = useState<GridRowsProp<Workout>>([]);
  useEffect(() => {
    get(ref(firebaseDB, "workouts"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setRows(Object.values(data));
        }
      })
      .catch((error) => toast.error(getTranslatedErrorMessage(error)));
  }, []);

  const localeText =
    userLocale === "de-DE"
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : undefined;

  const columns: GridColumns = [
    { field: "name", headerName: i18n["name"], flex: 1 },
    { field: "description", headerName: i18n["description"], flex: 1 },
    {
      field: "exerciseCount",
      headerName: i18n["workout.exerciseCount"],
      valueGetter: ({ row }) => row?.exercises?.length,
      flex: 0.5,
    },
  ];

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "workouts",
  });
  const selectWorkouts = () => {
    const newWorkouts = intersectionWith(
      rows,
      selectionModel,
      (o, id) => o.id === id
    );
    append(newWorkouts);
    setSelectionModel([]);
    setIsModalOpen(false);
  };

  return (
    <Box>
      <ListSubheader sx={{ padding: 0 }} component="div">
        {i18n["trainingPlan.selected"]}
      </ListSubheader>
      <Box
        gap={3}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!disabled && (
          <Button onClick={() => setIsModalOpen(true)} startIcon={<AddIcon />}>
            {i18n["add"]}
          </Button>
        )}
        {fields.map((field, index) => (
          <Box
            mt={1}
            key={field.id}
            display="flex"
            alignItems="center"
            columnGap={2}
          >
            <TextField
              disabled={disabled}
              required
              fullWidth
              value={field.name}
              label={i18n["workout"]}
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />

            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel required id="workout-selection-dayOfWeek-label">
                  {i18n["dayOfWeek"]}
                </InputLabel>
                <Controller
                  render={({ field: { value, onChange, ref } }) => (
                    <Select
                      disabled={disabled}
                      required
                      labelId="workout-selection-dayOfWeek-label"
                      id="workout-selection-dayOfWeek-select"
                      value={value || ""}
                      label={i18n["dayOfWeek"]}
                      onChange={(event) => onChange(event.target.value)}
                      inputRef={ref}
                    >
                      <MenuItem key="day-select-empty" value={""}></MenuItem>
                      {daysOfWeekShort.map((day) => (
                        <MenuItem key={`day-select-${day}`} value={day}>
                          {getTranslatedDayOfWeek(day)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                  name={`workouts.${index}.dayOfWeek`}
                  control={control}
                />
              </FormControl>
            </Box>

            {!disabled && (
              <IconButton onClick={() => remove(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Box>
      <Modal
        disableEnforceFocus
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70vw",
            height: "70vh",
            bgcolor: "background.paper",
            padding: 4,
            boxShadow: 24,
          }}
        >
          <Box display="flex" sx={{ flexGrow: 1 }}>
            <DataGrid
              // This is needed because data grid filter is not working properly in a modal
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      {
                        columnField: "name",
                        operatorValue: "contains",
                        value: "",
                      },
                    ],
                  },
                },
              }}
              sx={{
                // disable last column seperator
                "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                  {
                    display: "none",
                  },
              }}
              rows={rows}
              columns={columns}
              localeText={localeText}
              components={{ Toolbar: CustomToolbar }}
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
            />
          </Box>
          <Button
            onClick={selectWorkouts}
            disabled={selectionModel.length < 1}
            sx={{ mt: 2, alignSelf: "flex-end" }}
          >
            {i18n["add"]}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};
