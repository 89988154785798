import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ReactNode } from "react";
import { useLocale } from "./LocaleProvider";

export const DateProvider = ({ children }: { children: ReactNode }) => {
  const userLocale = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={userLocale}>
      {children}
    </LocalizationProvider>
  );
};
