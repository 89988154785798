import { LoadingButton } from "@mui/lab";
import { Box, Paper, TextField, Typography } from "@mui/material";
import { Workout } from "@peakconcepts/physio-types/Workout";
import { push, ref, set } from "firebase/database";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HomeHeader } from "../../../components/HomeHeader";
import { firebaseDB } from "../../../config/firebase";
import { useI18n } from "../../../translation";
import { useExerciseListData } from "../components/ExerciseLists/useExerciseListData";
import { ExerciseLists } from "../components/ExerciseLists/ExerciseLists";
import { useTranslationHelpers } from "../../../translation/useTranslationHelpers";
import { checkExistsBy } from "../../../service/checkExistsBy";

export const NewWorkout = () => {
  const i18n = useI18n();
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const navigate = useNavigate();

  const addWorkout = async (data: Workout) => {
    const id = push(ref(firebaseDB, "workouts")).key;
    set(ref(firebaseDB, `workouts/${id}`), {
      ...data,
      id,
      name: data.name.trim(),
      createdOn: new Date().toISOString(),
    });
  };

  const { register, handleSubmit, clearErrors } = useForm<Workout>({
    reValidateMode: "onSubmit",
  });
  const {
    selectedExercises,
    setSelectedExercises,
    availableExercises,
    setAvailableExercises,
  } = useExerciseListData([]);

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit: SubmitHandler<Workout> = async (data) => {
    setIsLoading(true);
    const newData: Workout = {
      ...data,
      exercises: (selectedExercises || []).map((exercise, index) => ({
        exerciseId: exercise.id,
        order: index,
      })),
    };
    try {
      const alreadyExists = await checkExistsBy({
        prop: "name",
        path: "workouts",
        value: data.name.trim(),
      });
      if (alreadyExists) {
        toast.error(i18n["error.workout.alreadyExists"]);
        return undefined;
      }
      await addWorkout(newData);
      toast.success(i18n["workout.success.created"]);
      navigate(-1);
    } catch (error) {
      toast.error(getTranslatedErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => clearErrors()}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <HomeHeader title={i18n["workout.new.title"]} />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        p={2}
        pl={3}
        rowGap={4}
        component={Paper}
        elevation={2}
        height="100%"
      >
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          {i18n["form.new.subtitle"]}
        </Typography>

        <TextField
          sx={{ width: "50%" }}
          required
          label={i18n["name"]}
          {...register("name", { required: true })}
        />
        <TextField
          sx={{ width: "50%" }}
          label={i18n["description"]}
          multiline
          {...register("description")}
        />
        <ExerciseLists
          selectedExercises={selectedExercises}
          setSelectedExercises={setSelectedExercises}
          availableExercises={availableExercises}
          setAvailableExercises={setAvailableExercises}
        />

        <LoadingButton
          sx={{ alignSelf: "flex-start" }}
          type="submit"
          loading={isLoading}
          disabled={(selectedExercises || []).length < 1}
        >
          {i18n["form.buttons.save"]}
        </LoadingButton>
      </Box>
    </Box>
  );
};
