import { LoadingButton } from '@mui/lab'
import { Box, Grid, Paper, TextField, Typography } from '@mui/material'
import { TrainingPlan } from '@peakconcepts/physio-types/TrainingPlan'
import { Workout } from '@peakconcepts/physio-types/Workout'
import { get, push, ref, set, update } from 'firebase/database'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { HomeHeader } from '../../../components/HomeHeader'
import { firebaseDB } from '../../../config/firebase'
import { useI18n } from '../../../translation'
import { useTranslationHelpers } from '../../../translation/useTranslationHelpers'
import { DayOfWeekShort } from '../../../utils/types/DayOfWeek'
import { checkMinDate } from '../../../utils/validation/checkMinDate'
import { UserSelection } from '../components/UserSelection'
import { WorkoutSelection } from '../components/WorkoutSelection'
import { generateUserWorkouts } from '../generateUserWorkouts'

export type CustomTrainingPlan = {
  workouts: ({
    dayOfWeek?: DayOfWeekShort
  } & Workout)[]
} & Omit<TrainingPlan, 'workouts'>

export const NewTrainingPlan = () => {
  const i18n = useI18n()
  const { getTranslatedErrorMessage } = useTranslationHelpers()

  const addUserWorkouts = async (data: CustomTrainingPlan) => {
    const userWorkouts = generateUserWorkouts(data)
    const userWorkoutRef = ref(firebaseDB, `userWorkouts/${data.userId}`)
    update(
      userWorkoutRef,
      Object.fromEntries(
        userWorkouts.map(userWorkout => {
          const newKey = push(userWorkoutRef).key
          return [newKey, { ...userWorkout, id: newKey }]
        })
      )
    )
  }

  const addTrainingPlan = async (data: CustomTrainingPlan) => {
    const { workouts, ...otherData } = data
    const cleanedWorkouts = workouts.map(({ id, dayOfWeek }) => ({
      workoutId: id,
      dayOfWeek,
    }))
    set(ref(firebaseDB, `trainingPlans/${data.userId}`), {
      ...otherData,
      workouts: cleanedWorkouts,
      createdOn: new Date().toISOString(),
    })
  }

  const formMethods = useForm<CustomTrainingPlan>({
    reValidateMode: 'onSubmit',
  })
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    getValues,
  } = formMethods
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<CustomTrainingPlan> = async data => {
    if (!data.userId) {
      toast.error(i18n['error.trainingPlan.noUser'])
      return undefined
    }

    setIsLoading(true)
    try {
      const alreadyExists = await get(
        ref(firebaseDB, `trainingPlans/${data.userId}`)
      ).then(snapshot => snapshot.exists())
      if (alreadyExists) {
        toast.error(i18n['error.trainingPlan.alreadyExists'])
        return undefined
      }
      await addTrainingPlan(data)
      await addUserWorkouts(data)
      toast.success(i18n['trainingPlan.success.created'])
    } catch (error) {
      toast.error(getTranslatedErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => clearErrors()}>
        <HomeHeader title={i18n['trainingPlan.new.title']} />
        <Grid container>
          <Grid
            lg={6}
            p={2}
            pl={3}
            container
            item
            rowGap={4}
            component={Paper}
            elevation={2}>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
              {i18n['form.new.subtitle']}
            </Typography>

            <Grid item xs={12}>
              <UserSelection />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" columnGap={2}>
                <TextField
                  label={i18n['trainingPlan.startDate']}
                  type="date"
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('startDate', { required: true })}
                />
                <TextField
                  label={i18n['trainingPlan.endDate']}
                  type="date"
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('endDate', {
                    required: true,
                    validate: {
                      minDate: endDate =>
                        checkMinDate(endDate, getValues('startDate')),
                    },
                  })}
                  error={Boolean(errors.endDate?.type === 'minDate')}
                  helperText={
                    errors.endDate?.type === 'minDate' &&
                    i18n['error.form.endDate.minDate']
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <WorkoutSelection />
            </Grid>

            <Grid item>
              <LoadingButton
                disabled={
                  !getValues('workouts') || getValues('workouts').length < 1
                }
                type="submit"
                loading={isLoading}>
                {i18n['form.buttons.save']}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
