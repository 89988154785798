import {
  DataGrid,
  GridActionsCellItem,
  deDE,
  GridColumns,
  GridRowsProp,
} from "@mui/x-data-grid";
import { useI18n } from "../../translation";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocale } from "../../service/LocaleProvider";
import { CustomToolbar } from "../../components/grid/CustomToolbar";
import { useNavigate } from "react-router-dom";
import { onValue, ref, remove } from "firebase/database";
import { firebaseDB } from "../../config/firebase";
import { useEffect, useState } from "react";
import { Workout } from "@peakconcepts/physio-types/Workout";
import { HomeHeader } from "../../components/HomeHeader";
import { toast } from "react-toastify";
import { useTranslationHelpers } from "../../translation/useTranslationHelpers";

export const Workouts = () => {
  const i18n = useI18n();
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const userLocale = useLocale();
  const navigate = useNavigate();

  const [rows, setRows] = useState<GridRowsProp<Workout>>([]);
  useEffect(() => {
    const dbRef = ref(firebaseDB, "workouts");
    return onValue(
      dbRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val() as Record<string, Workout>;
          setRows(Object.values(data));
        } else setRows([]);
      },
      (error) => toast.error(getTranslatedErrorMessage(error))
    );
  }, []);

  const localeText =
    userLocale === "de-DE"
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : undefined;

  const onAddNewEntry = () => navigate("/workouts/new");

  const deleteWorkout = async (workoutId: string) => {
    remove(ref(firebaseDB, `workouts/${workoutId}`));
  };

  const [isLoading, setIsLoading] = useState(false);
  const onDeleteEntry = (workoutId: string) => {
    setIsLoading(true);
    deleteWorkout(workoutId)
      .then((response) => {
        toast.success(i18n["workout.success.deleted"]);
      })
      .catch((error) => toast.error(getTranslatedErrorMessage(error)))
      .finally(() => setIsLoading(false));
  };

  const columns: GridColumns = [
    { field: "name", headerName: i18n["name"], flex: 1 },
    { field: "description", headerName: i18n["description"], flex: 1 },
    {
      field: "exerciseCount",
      headerName: i18n["workout.exerciseCount"],
      valueGetter: ({ row }) => row?.exercises?.length,
      flex: 0.5,
    },
    {
      field: "actions",
      type: "actions",
      headerName: i18n["grid.actions"],
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(`/workouts/${row.id}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            disabled={row?.usedIn?.length > 0}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => onDeleteEntry(row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <HomeHeader title={i18n["drawer.workouts"]} />
      <DataGrid
        sx={{
          // disable last column seperator
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
        rows={rows}
        columns={columns}
        localeText={localeText}
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: { onAddNewEntry },
        }}
        loading={isLoading}
      />
    </>
  );
};
