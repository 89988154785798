import { LoadingButton } from '@mui/lab'
import { Grid, Paper, TextField, Typography } from '@mui/material'
import { User } from '@peakconcepts/physio-types/User'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { HomeHeader } from '../../../components/HomeHeader'
import { useI18n } from '../../../translation'
import { useTranslationHelpers } from '../../../translation/useTranslationHelpers'
import { emailPattern } from '../../../utils/validationPatterns'
import { UserTypeSelection } from '../components/UserTypeSelection'

export type NewUserType = {
  password: string
} & User

export const NewUser = () => {
  const i18n = useI18n()
  const { getTranslatedErrorMessage } = useTranslationHelpers()
  const navigate = useNavigate()
  const functions = getFunctions()
  const addUser = httpsCallable(functions, 'addUser')
  const formMethods = useForm<NewUserType>({
    reValidateMode: 'onSubmit',
    defaultValues: { type: 'standard' },
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = formMethods
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<NewUserType> = data => {
    setIsLoading(true)
    addUser(data)
      .then(response => {
        toast.success(i18n['user.success.created'])
        navigate(-1)
      })
      .catch(error => toast.error(getTranslatedErrorMessage(error)))
      .finally(() => setIsLoading(false))
  }

  return (
    <FormProvider {...formMethods}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => clearErrors()}>
        <HomeHeader title={i18n['user.new.title']} />
        <Grid container>
          <Grid
            lg={6}
            p={2}
            pl={3}
            container
            item
            rowGap={4}
            component={Paper}
            elevation={2}>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
              {i18n['form.new.subtitle']}
            </Typography>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={i18n['user.firstName']}
                {...register('firstName', { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={i18n['user.lastName']}
                {...register('lastName', { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(errors.email?.type === 'pattern')}
                required
                fullWidth
                label={i18n['email']}
                {...register('email', {
                  required: true,
                  pattern: emailPattern,
                })}
                helperText={
                  errors.email?.type === 'pattern' &&
                  i18n['error.form.email.invalid']
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(errors.password?.type === 'minLength')}
                required
                fullWidth
                label={i18n['password']}
                {...register('password', { required: true, minLength: 8 })}
                helperText={
                  errors.password?.type === 'minLength' &&
                  i18n['error.form.password.minLength']
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={i18n['user.city']}
                {...register('location.city')}
              />
            </Grid>
            <Grid item xs={12}>
              <UserTypeSelection />
            </Grid>

            <Grid item>
              <LoadingButton type="submit" loading={isLoading}>
                {i18n['form.buttons.save']}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
