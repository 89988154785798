import { Box } from "@mui/material";
import { get, ref } from "firebase/database";
import { Params } from "react-router-dom";
import { HomeHeader } from "../../../components/HomeHeader";
import { firebaseDB } from "../../../config/firebase";
import { useI18n } from "../../../translation";
import { EditExerciseData } from "./EditExerciseData";
import { ExerciseInfoPaper } from "./ExerciseInfoPaper";

export const EditExercise = () => {
  const i18n = useI18n();

  return (
    <>
      <HomeHeader title={i18n["exercise.edit.title"]} />
      <Box display="flex" gap={4}>
        <Box display="flex" flexDirection="column" gap={4} flex={1}>
          <EditExerciseData />
        </Box>
        <Box display="flex" flexDirection="column" gap={4} flex={1}>
          <ExerciseInfoPaper />
        </Box>
      </Box>
    </>
  );
};

export const exerciseLoader = async ({ params }: { params: Params }) => {
  const { exerciseId } = params;
  const dbRef = ref(firebaseDB, `exercises/${exerciseId}`);
  return get(dbRef)
    .then((snapshot) => {
      if (snapshot.exists()) return snapshot.val();
    })
    .catch((error) => {
      console.error(error);
      throw new Response("", { status: 404 });
    });
};
