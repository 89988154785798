import { initializeApp } from 'firebase/app'
// eslint-disable-next-line
import { getAuth } from 'firebase/auth'
// eslint-disable-next-line
import { getDatabase } from 'firebase/database'
// eslint-disable-next-line
import { getFunctions } from 'firebase/functions'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyD5L-AlGndo69HZWM1FEwl7ko1_Kc5xy1g',
  authDomain: 'physio-app-817ac.firebaseapp.com',
  databaseURL:
    'https://physio-app-817ac-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'physio-app-817ac',
  storageBucket: 'physio-app-817ac.appspot.com',
  messagingSenderId: '576712417186',
  appId: '1:576712417186:web:32d2b862184ed1bbf05cfb',
  measurementId: 'G-2RL84KESR2',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
export const firebaseAuth = getAuth(app)
// Enable for testing
// if (process.env.NODE_ENV === "development")
//   connectAuthEmulator(firebaseAuth, "http://localhost:9099");

// Initialize Realtime Database and get a reference to the service
export const firebaseDB = getDatabase(app)
// Enable for testing
// if (process.env.NODE_ENV === "development")
//   connectDatabaseEmulator(firebaseDB, "localhost", 9000);

// Initialize Functions and get a reference to the service
export const firebaseFunctions = getFunctions(app)
// Enable for testing
// if (process.env.NODE_ENV === "development")
//   connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
