import { Box, Paper, Typography } from "@mui/material";
import { DataGrid, deDE, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { UserWorkout } from "@peakconcepts/physio-types/UserWorkout";
import { get, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomToolbar } from "../../../components/grid/CustomToolbar";
import { firebaseDB } from "../../../config/firebase";
import { useLocale } from "../../../service/LocaleProvider";
import { useI18n } from "../../../translation";
import { useTranslationHelpers } from "../../../translation/useTranslationHelpers";
import { useDateHelpers } from "../../../utils/useLocalDate";

export type CustomUserWorkout = {
  workoutName: string;
} & UserWorkout;

export const UserWorkoutsInfo = () => {
  const { userId } = useParams();
  const i18n = useI18n();
  const {
    getTranslatedErrorMessage,
    getTranslatedFeedback,
    getTranslatedDayOfWeek,
  } = useTranslationHelpers();

  const { getLocalDate } = useDateHelpers();
  const userLocale = useLocale();

  const [rows, setRows] = useState<GridRowsProp<CustomUserWorkout>>([]);
  useEffect(() => {
    const dbRef = ref(firebaseDB, `userWorkouts/${userId}`);
    return onValue(
      dbRef,
      (snapshot) => {
        if (snapshot.exists()) {
          // userWorkouts have only the workoutId
          const userWorkouts = Object.values(snapshot.val()) as UserWorkout[];
          // get workouts for the workout name
          Promise.all(
            userWorkouts.map((userWorkout) =>
              get(ref(firebaseDB, `workouts/${userWorkout.workoutId}`))
            )
          ).then((snapshots) => {
            const workouts = snapshots
              .filter((snapshot) => snapshot.exists())
              .map((snapshot) => snapshot.val());
            // { id1: name1, id2: name2, ...}
            const workoutNames = Object.fromEntries(
              workouts.map(({ id, name }) => [id, name])
            );
            const newRows = userWorkouts.map((userWorkout) => ({
              ...userWorkout,
              workoutName: workoutNames[userWorkout.workoutId],
            }));
            setRows(newRows);
          });
        } else setRows([]);
      },
      (error) => toast.error(getTranslatedErrorMessage(error))
    );
  }, []);

  const localeText =
    userLocale === "de-DE"
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : undefined;

  const columns: GridColumns = [
    { field: "workoutName", headerName: i18n["workout"], flex: 1 },
    {
      field: "dayOfWeek",
      headerName: i18n["dayOfWeek"],
      valueGetter: ({ row }) => getTranslatedDayOfWeek(row?.dayOfWeek),
      flex: 1,
    },
    {
      field: "scheduledFor",
      headerName: i18n["trainingPlan.workouts.scheduledFor"],
      valueGetter: ({ row }) =>
        getLocalDate(row?.scheduledFor, { onlyDate: true }),
      flex: 1,
    },
    {
      field: "completedOn",
      headerName: i18n["trainingPlan.workouts.completedOn"],
      valueGetter: ({ row }) =>
        getLocalDate(row?.completedOn, { onlyDate: true }),
      flex: 1,
    },
    {
      field: "feedback",
      headerName: i18n["trainingPlan.workouts.feedback"],
      valueGetter: ({ row }) => getTranslatedFeedback(row?.feedback),
      flex: 1,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      pl={3}
      mb={4}
      rowGap={4}
      component={Paper}
      elevation={3}
      height={700}
    >
      <Typography color="primary" sx={{ fontWeight: "bold" }}>
        {i18n["drawer.workouts"]}
      </Typography>
      <DataGrid
        sx={{
          // disable last column seperator
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
        rows={rows}
        columns={columns}
        localeText={localeText}
        components={{ Toolbar: CustomToolbar }}
      />
    </Box>
  );
};
