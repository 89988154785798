const DAY_OF_WEEK_LONG = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;
export const daysOfWeekLong = [...DAY_OF_WEEK_LONG];
export type DayOfWeekLong = typeof daysOfWeekLong[number];

const DAY_OF_WEEK_SHORT = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
] as const;
export const daysOfWeekShort = [...DAY_OF_WEEK_SHORT];
export type DayOfWeekShort = typeof daysOfWeekShort[number];
