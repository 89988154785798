import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { theme } from "./config/theme";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./service/AuthProvider";
import { AdminProvider } from "./service/AdminProvider";
import { ToastContainer } from "react-toastify";
import { LocaleProvider } from "./service/LocaleProvider";
import { DateProvider } from "./service/DateProvider";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// CAUTION! No Strict-Mode.
// react-beautiful-dnd is not working with it
root.render(
  <AuthProvider>
    <AdminProvider>
      <LocaleProvider>
        <ThemeProvider theme={theme}>
          <DateProvider>
            <CssBaseline />
            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              style={{ maxWidth: 400 }}
            />
            <App />
          </DateProvider>
        </ThemeProvider>
      </LocaleProvider>
    </AdminProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
