import {
  DataGrid,
  GridActionsCellItem,
  deDE,
  GridColumns,
  GridRowsProp,
} from '@mui/x-data-grid'
import { useI18n } from '../../translation'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useLocale } from '../../service/LocaleProvider'
import { CustomToolbar } from '../../components/grid/CustomToolbar'
import { useNavigate } from 'react-router-dom'
import { onValue, ref, remove } from 'firebase/database'
import { firebaseDB } from '../../config/firebase'
import { useEffect, useState } from 'react'
import { User } from '@peakconcepts/physio-types/User'
import { HomeHeader } from '../../components/HomeHeader'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { toast } from 'react-toastify'
import { useTranslationHelpers } from '../../translation/useTranslationHelpers'
import { useDateHelpers } from '../../utils/useLocalDate'

export const Users = () => {
  const i18n = useI18n()
  const { getTranslatedErrorMessage, getTranslatedUserType } =
    useTranslationHelpers()
  const { getLocalDate } = useDateHelpers()
  const userLocale = useLocale()
  const navigate = useNavigate()

  const [rows, setRows] = useState<GridRowsProp<User>>([])
  useEffect(() => {
    const dbRef = ref(firebaseDB, 'users')
    return onValue(
      dbRef,
      snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val() as Record<string, User>
          setRows(Object.values(data))
        } else setRows([])
      },
      error => toast.error(getTranslatedErrorMessage(error))
    )
  }, [])

  const localeText =
    userLocale === 'de-DE'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : undefined

  const onAddNewEntry = () => navigate('/users/new')

  const functions = getFunctions()
  const deleteUser = httpsCallable(functions, 'deleteUser')
  const [isLoading, setIsLoading] = useState(false)
  const onDeleteEntry = async (userId: string) => {
    setIsLoading(true)
    try {
      await deleteUser({ id: userId })
      await remove(ref(firebaseDB, `trainingPlans/${userId}`))
      await remove(ref(firebaseDB, `userWorkouts/${userId}`))
      toast.success(i18n['user.success.deleted'])
    } catch (error) {
      toast.error(getTranslatedErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  const columns: GridColumns = [
    { field: 'email', headerName: i18n['email'], flex: 1 },
    { field: 'firstName', headerName: i18n['user.firstName'], flex: 1 },
    { field: 'lastName', headerName: i18n['user.lastName'], flex: 1 },
    {
      field: 'city',
      headerName: i18n['user.city'],
      valueGetter: ({ row }) => row?.location?.city,
      flex: 1,
    },
    {
      field: 'type',
      headerName: i18n['user.type'],
      valueGetter: ({ row }) => getTranslatedUserType(row?.type),
      flex: 1,
    },
    {
      field: 'lastLoginOn',
      headerName: i18n['user.lastLoginOn'],
      valueGetter: ({ row }) => getLocalDate(row?.lastLoginOn),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: i18n['grid.actions'],
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(`/users/${row.id}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => onDeleteEntry(row.id)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  return (
    <>
      <HomeHeader title={i18n['drawer.users']} />
      <DataGrid
        sx={{
          // disable last column seperator
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
            {
              display: 'none',
            },
        }}
        rows={rows}
        columns={columns}
        localeText={localeText}
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: { onAddNewEntry },
        }}
        loading={isLoading}
      />
    </>
  )
}
