import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Paper, TextField, Typography } from "@mui/material";
import { User } from "@peakconcepts/physio-types/User";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";
import { useI18n } from "../../../translation";
import { useTranslationHelpers } from "../../../translation/useTranslationHelpers";
import { emailPattern } from "../../../utils/validationPatterns";

export type LoginData = {
  password: string;
} & User;

export const EditUserLogin = () => {
  const i18n = useI18n();
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const defaultValues = useLoaderData() as LoginData | undefined;
  const revalidator = useRevalidator();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
    reset,
  } = useForm<LoginData>({
    reValidateMode: "onSubmit",
    defaultValues: { ...defaultValues, password: "" },
  });

  const functions = getFunctions();
  const updateUserLogin = httpsCallable(functions, "updateUserLogin");
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit: SubmitHandler<LoginData> = (data) => {
    setIsLoading(true);
    updateUserLogin(data)
      .then((response) => {
        toast.success(i18n["user.success.updated"]);
        revalidator.revalidate();
        reset({ ...data, password: "" });
      })
      .catch((error) => toast.error(getTranslatedErrorMessage(error)))
      .finally(() => setIsLoading(false));
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => clearErrors()}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        pl={3}
        rowGap={4}
        component={Paper}
        elevation={2}
      >
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          {i18n["user.subtitle.editLoginData"]}
        </Typography>
        <TextField
          error={Boolean(errors.email?.type === "pattern")}
          required
          fullWidth
          label={i18n["email"]}
          {...register("email", {
            required: true,
            pattern: emailPattern,
          })}
          helperText={
            errors.email?.type === "pattern" && i18n["error.form.email.invalid"]
          }
        />
        <TextField
          error={Boolean(errors.password?.type === "minLength")}
          fullWidth
          label={i18n["newPassword"]}
          {...register("password", { minLength: 8 })}
          helperText={
            errors.password?.type === "minLength" &&
            i18n["error.form.password.minLength"]
          }
        />
        <LoadingButton
          sx={{ alignSelf: "flex-start" }}
          type="submit"
          disabled={!isDirty}
          loading={isLoading}
        >
          {i18n["form.buttons.save"]}
        </LoadingButton>
      </Box>
    </form>
  );
};
