import {
  equalTo,
  get,
  limitToLast,
  orderByChild,
  query,
  ref,
} from "firebase/database";
import { firebaseDB } from "../config/firebase";

export const checkExistsBy = async ({
  prop,
  path,
  value,
}: {
  prop: string;
  path: string;
  value: string;
}) => {
  return get(
    query(
      ref(firebaseDB, path),
      orderByChild(prop),
      equalTo(value),
      limitToLast(1)
    )
  ).then((snapshot) => snapshot.exists());
};
