import { Box, Paper, TextField, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useLoaderData } from "react-router-dom";
import { useI18n } from "../../../translation";
import { checkMinDate } from "../../../utils/validation/checkMinDate";
import { UserSelection } from "../components/UserSelection";
import { WorkoutSelection } from "../components/WorkoutSelection";
import { CustomTrainingPlan } from "../NewTrainingPlan/NewTrainingPlan";

export const EditTrainingPlanData = () => {
  const i18n = useI18n();
  //const { getTranslatedErrorMessage } = useTranslationHelpers();
  const defaultValues = useLoaderData() as CustomTrainingPlan | undefined;
  //const revalidator = useRevalidator();

  // const updateTrainingPlan = async (data: CustomTrainingPlan) => {
  //   const { workouts, ...otherData } = data;
  //   const cleanedWorkouts = workouts.map(({ id, dayOfWeek }) => ({
  //     workoutId: id,
  //     dayOfWeek,
  //   }));
  //   update(ref(firebaseDB, `trainingPlans/${data.id}`), {
  //     ...otherData,
  //     workouts: cleanedWorkouts,
  //     updatedOn: new Date().toISOString(),
  //   });
  // };

  const formMethods = useForm<CustomTrainingPlan>({
    reValidateMode: "onSubmit",
    defaultValues,
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    getValues,
  } = formMethods;
  const onSubmit: SubmitHandler<CustomTrainingPlan> = (data) => {
    // setIsLoading(true);
    // updateTrainingPlan(data)
    //   .then((response) => {
    //     toast.success(i18n["trainingPlan.success.updated"]);
    //     revalidator.revalidate();
    //     reset(data);
    //   })
    //   .catch((error) => toast.error(getTranslatedErrorMessage(error)))
    //   .finally(() => setIsLoading(false));
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => clearErrors()}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          p={2}
          pl={3}
          rowGap={4}
          component={Paper}
          elevation={2}
          height="100%"
        >
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            {i18n["form.view.subtitle"]}
          </Typography>

          <UserSelection disabled />

          <Box display="flex" columnGap={2}>
            <TextField
              disabled
              label={i18n["trainingPlan.startDate"]}
              type="date"
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              {...register("startDate", { required: true })}
            />
            <TextField
              disabled
              label={i18n["trainingPlan.endDate"]}
              type="date"
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              {...register("endDate", {
                required: true,
                validate: {
                  minDate: (endDate) =>
                    checkMinDate(endDate, getValues("startDate")),
                },
              })}
              error={Boolean(errors.endDate?.type === "minDate")}
              helperText={
                errors.endDate?.type === "minDate" &&
                i18n["error.form.endDate.minDate"]
              }
            />
          </Box>

          <WorkoutSelection disabled />

          {/* <LoadingButton
            sx={{ alignSelf: "start" }}
            disabled={
              !getValues("workouts") || getValues("workouts").length < 1
            }
            type="submit"
            loading={isLoading}
          >
            {i18n["form.buttons.save"]}
          </LoadingButton> */}
        </Box>
      </Box>
    </FormProvider>
  );
};
