import { UserType } from "@peakconcepts/physio-types/User";
import { useI18n } from ".";
import { DayOfWeekShort } from "../utils/types/DayOfWeek";

export const useTranslationHelpers = () => {
  const i18n = useI18n();

  const getTranslatedErrorMessage = ({ code, details }: any) => {
    const errorCode =
      code === "functions/unknown" && details ? details.code : code;
    switch (errorCode) {
      case "auth/email-already-exists":
        return i18n["firebase.error.auth.email-already-exists"];
      case "auth/invalid-email":
        return i18n["firebase.error.auth.invalid-email"];
      case "auth/invalid-password":
        return i18n["firebase.error.auth.invalid-password"];
      case "auth/user-not-found":
        return i18n["firebase.error.auth.user-not-found"];
      case "not-found":
        return i18n["firebase.error.not-found"];
      case "already-exists":
        return i18n["firebase.error.already-exists"];
      case "resource-exhausted":
        return i18n["firebase.error.resource-exhausted"];
      case "unavailable":
        return i18n["firebase.error.unavailable"];
      case "unauthenticated":
        return i18n["firebase.error.unauthenticated"];
      default:
        return i18n["error.unknown"];
    }
  };

  const getTranslatedFeedback = (feedback: string | undefined) => {
    switch (feedback) {
      case "":
      case undefined:
        return i18n["unknown"];
      case "hard":
        return i18n["trainingPlan.workouts.feedback.hard"];
      case "perfect":
        return i18n["trainingPlan.workouts.feedback.perfect"];
      case "easy":
        return i18n["trainingPlan.workouts.feedback.easy"];
      default:
        return i18n["error.unknownValue"];
    }
  };

  const getTranslatedDayOfWeek = (dayOfWeek: DayOfWeekShort | undefined) => {
    switch (dayOfWeek) {
      case undefined:
        return i18n["unknown"];
      case "Mon":
        return i18n["dayOfWeek.long.Mon"];
      case "Tue":
        return i18n["dayOfWeek.long.Tue"];
      case "Wed":
        return i18n["dayOfWeek.long.Wed"];
      case "Thu":
        return i18n["dayOfWeek.long.Thu"];
      case "Fri":
        return i18n["dayOfWeek.long.Fri"];
      case "Sat":
        return i18n["dayOfWeek.long.Sat"];
      case "Sun":
        return i18n["dayOfWeek.long.Sun"];
      default:
        return i18n["error.unknownValue"];
    }
  };

  const getTranslatedUserType = (type: UserType | undefined) => {
    switch (type) {
      case "standard":
        return i18n["user.type.standard"];
      case "private":
        return i18n["user.type.private"];
      default:
        return i18n["error.unknownValue"];
    }
  };

  return {
    getTranslatedErrorMessage,
    getTranslatedFeedback,
    getTranslatedDayOfWeek,
    getTranslatedUserType,
  };
};
