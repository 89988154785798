import AddIcon from '@mui/icons-material/Add'
import { Button, Box } from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { useI18n } from '../../translation'

export const CustomToolbar = ({
  onAddNewEntry,
}: {
  onAddNewEntry?: () => void
}) => {
  const i18n = useI18n()

  return (
    <GridToolbarContainer sx={{ mt: 2 }}>
      <GridToolbarColumnsButton sx={{ color: 'inherit' }} />
      <GridToolbarFilterButton sx={{ color: 'inherit' }} />
      <GridToolbarDensitySelector sx={{ color: 'inherit' }} />
      <GridToolbarExport
        sx={{ color: 'inherit' }}
        csvOptions={{
          fileName: 'MediHome_Admin_Daten',
        }}
      />
      {onAddNewEntry && (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Button
            color="secondary"
            startIcon={<AddIcon />}
            onClick={onAddNewEntry}>
            {i18n['grid.addEntry']}
          </Button>
        </Box>
      )}
    </GridToolbarContainer>
  )
}
