import { Admin } from "@peakconcepts/physio-types/Admin";
import { onValue, ref } from "firebase/database";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { firebaseDB } from "../config/firebase";
import { useI18n } from "../translation";
import { useTranslationHelpers } from "../translation/useTranslationHelpers";
import { useAuth } from "./AuthProvider";

export const AdminContext = createContext<Admin | null | undefined>(undefined);

export const AdminProvider = ({ children }: { children: ReactNode }) => {
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const [admin, setAdmin] = useState<Admin | null>();
  const { authUser } = useAuth();

  // if authUser is loaded or admin data changes => update admin state
  useEffect(() => {
    if (!authUser?.uid) return undefined;
    const adminRef = ref(firebaseDB, "admins/" + authUser.uid);
    return onValue(
      adminRef,
      (snapshot) => {
        const data = snapshot.val();
        setAdmin(data);
      },
      (error) => toast.error(getTranslatedErrorMessage(error))
    );
  }, [authUser?.uid]);

  return (
    <AdminContext.Provider value={admin}>{children}</AdminContext.Provider>
  );
};

export const useAdmin = () => {
  const i18n = useI18n();
  const admin = useContext(AdminContext);
  const isAdminLoading = admin === undefined;
  const { firstName, lastName } = admin || {};

  const getName = () => {
    if (isAdminLoading) return "";
    if (!firstName || !lastName) return i18n["defaultUserName"];
    return `${firstName} ${lastName}`;
  };

  return { admin, getName, isAdminLoading };
};
