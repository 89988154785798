import PersonAdd from '@mui/icons-material/PersonAdd'
import { Box, Button, Modal, TextField } from '@mui/material'
import { DataGrid, deDE, GridColumns, GridRowsProp } from '@mui/x-data-grid'
import { User } from '@peakconcepts/physio-types/User'
import { get, ref } from 'firebase/database'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { CustomToolbar } from '../../../components/grid/CustomToolbar'
import { firebaseDB } from '../../../config/firebase'
import { useLocale } from '../../../service/LocaleProvider'
import { useI18n } from '../../../translation'
import { useTranslationHelpers } from '../../../translation/useTranslationHelpers'
import { CustomTrainingPlan } from '../NewTrainingPlan/NewTrainingPlan'

export const UserSelection = ({ disabled }: { disabled?: boolean }) => {
  const i18n = useI18n()
  const { getTranslatedErrorMessage, getTranslatedUserType } =
    useTranslationHelpers()
  const userLocale = useLocale()
  const { setValue, watch } = useFormContext<CustomTrainingPlan>()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [rows, setRows] = useState<GridRowsProp<User>>([])
  useEffect(() => {
    get(ref(firebaseDB, 'users'))
      .then(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          setRows(Object.values(data))
        }
      })
      .catch(error => toast.error(getTranslatedErrorMessage(error)))
  }, [])

  const localeText =
    userLocale === 'de-DE'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : undefined

  const [userEmail, setUserEmail] = useState('')
  const watchedUserId = watch('userId', '')
  useEffect(() => {
    if (watchedUserId)
      get(ref(firebaseDB, `users/${watchedUserId}`))
        .then(snapshot => {
          if (snapshot.exists()) {
            const user = snapshot.val() as User
            setUserEmail(user.email)
          }
        })
        .catch(error => toast.error(getTranslatedErrorMessage(error)))
  }, [watchedUserId])

  const selectUser = (user: User) => {
    setValue('userId', user.id)
    setIsModalOpen(false)
  }

  const columns: GridColumns = [
    { field: 'email', headerName: i18n['email'], flex: 1 },
    { field: 'firstName', headerName: i18n['user.firstName'], flex: 1 },
    { field: 'lastName', headerName: i18n['user.lastName'], flex: 1 },
    {
      field: 'type',
      headerName: i18n['user.type'],
      valueGetter: ({ row }) => getTranslatedUserType(row?.type),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: i18n['grid.actions'],
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <Button
            onClick={() => selectUser(row)}
            variant="outlined"
            startIcon={<PersonAdd />}>
            {i18n['choose']}
          </Button>,
        ]
      },
    },
  ]

  return (
    <Box display="flex" alignItems="center" columnGap={2}>
      <TextField
        disabled={disabled}
        sx={{
          flex: 1,
          minWidth: 100,
        }}
        value={userEmail}
        required
        label={i18n['email']}
      />

      {!disabled && (
        <Button onClick={() => setIsModalOpen(true)}>
          {i18n['trainingPlan.select.user']}
        </Button>
      )}
      <Modal
        disableEnforceFocus
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',
            height: '70vh',
            bgcolor: 'background.paper',
            padding: 4,
            boxShadow: 24,
          }}>
          <Box display="flex" sx={{ flexGrow: 1 }}>
            <DataGrid
              // This is needed because data grid filter is not working properly in a modal
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      {
                        columnField: 'email',
                        operatorValue: 'contains',
                        value: '',
                      },
                    ],
                  },
                },
              }}
              sx={{
                // disable last column seperator
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
                  {
                    display: 'none',
                  },
              }}
              rows={rows}
              columns={columns}
              localeText={localeText}
              components={{ Toolbar: CustomToolbar }}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
