import { LoadingButton } from "@mui/lab";
import { Grid, Paper, TextField, Typography } from "@mui/material";
import { Exercise } from "@peakconcepts/physio-types/Exercise";
import { push, ref, update } from "firebase/database";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HomeHeader } from "../../components/HomeHeader";
import { firebaseDB } from "../../config/firebase";
import { checkExistsBy } from "../../service/checkExistsBy";
import { useI18n } from "../../translation";
import { useTranslationHelpers } from "../../translation/useTranslationHelpers";

export const NewExercise = () => {
  const i18n = useI18n();
  const navigate = useNavigate();
  const { getTranslatedErrorMessage } = useTranslationHelpers();

  const addExercise = async (data: Exercise) => {
    push(ref(firebaseDB, "exercises"), {
      createdOn: new Date().toISOString(),
      name: data.name.trim(),
      video: { ...data.video },
      category: data.category,
      description: data.description,
    }).then((newRef) => update(newRef, { id: newRef.key }));
  };

  const { register, handleSubmit, clearErrors } = useForm<Exercise>({
    reValidateMode: "onSubmit",
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit: SubmitHandler<Exercise> = async (data) => {
    setIsLoading(true);
    try {
      const alreadyExists = await checkExistsBy({
        prop: "name",
        path: "exercises",
        value: data.name.trim(),
      });
      if (alreadyExists) {
        toast.error(i18n["error.exercise.alreadyExists"]);
        return undefined;
      }
      await addExercise(data);
      toast.success(i18n["exercise.success.created"]);
      navigate(-1);
    } catch (error) {
      toast.error(getTranslatedErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => clearErrors()}
    >
      <HomeHeader title={i18n["exercise.new.title"]} />
      <Grid container>
        <Grid
          lg={6}
          p={2}
          pl={3}
          container
          item
          rowGap={4}
          component={Paper}
          elevation={2}
        >
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            {i18n["form.new.subtitle"]}
          </Typography>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={i18n["name"]}
              {...register("name", { required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label={i18n["exercise.videoUrl"]}
              multiline
              {...register("video.url", { required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={i18n["exercise.category"]}
              {...register("category")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={i18n["description"]}
              multiline
              {...register("description")}
            />
          </Grid>

          <Grid item>
            <LoadingButton type="submit" loading={isLoading}>
              {i18n["form.buttons.save"]}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
