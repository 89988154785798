import { ListItem, ListItemText, styled } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";

const StyledListItem = styled(ListItem)<{ isDragging: boolean }>`
  // some basic styles to make the items look a bit nicer
  user-select: "none";
  border-radius: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  // change background colour if dragging
  background: ${({ isDragging }) => (isDragging ? "lightgreen" : "#7C99AC")};
`;

export const ExerciseListContent = ({
  items,
}: {
  items: any[] | undefined;
}) => {
  if (!items) return <></>;
  return (
    <>
      {items.map((item, index) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(provided, snapshot) => (
            <StyledListItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
              style={provided.draggableProps.style}
            >
              <ListItemText primary={item.name} secondary={item.category} />
            </StyledListItem>
          )}
        </Draggable>
      ))}
    </>
  );
};
