import { Exercise } from "@peakconcepts/physio-types/Exercise";
import { Workout } from "@peakconcepts/physio-types/Workout";
import { get, ref } from "firebase/database";
import { partition, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { firebaseDB } from "../../../../config/firebase";
import { toast } from "react-toastify";
import { useTranslationHelpers } from "../../../../translation/useTranslationHelpers";

export const useExerciseListData = (
  initialExercises: Workout["exercises"] | null | undefined
) => {
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const [availableExercises, setAvailableExercises] = useState<Exercise[]>();
  const [selectedExercises, setSelectedExercises] = useState<Exercise[]>();
  useEffect(() => {
    if (initialExercises === null) {
      setSelectedExercises([]);
    } else if (initialExercises) {
      get(ref(firebaseDB, "exercises"))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const allExercises = Object.values(data) as Exercise[];
            const [selected, available] = partition(allExercises, (item) => {
              return !!initialExercises.find(
                ({ exerciseId }) => exerciseId === item.id
              );
            });
            const sortedSelected = sortBy(
              selected,
              ({ id }) =>
                initialExercises.find((item) => item.exerciseId === id)?.order
            );
            setSelectedExercises(sortedSelected);
            setAvailableExercises(available);
          } else {
            setSelectedExercises([]);
            setAvailableExercises([]);
          }
        })
        .catch((error) => toast.error(getTranslatedErrorMessage(error)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialExercises)]);

  return {
    selectedExercises,
    setSelectedExercises,
    availableExercises,
    setAvailableExercises,
  };
};
