import { LoadingButton } from "@mui/lab";
import { Box, Paper, TextField, Typography } from "@mui/material";
import { Workout } from "@peakconcepts/physio-types/Workout";
import { ref, update } from "firebase/database";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";
import { firebaseDB } from "../../../config/firebase";
import { checkExistsBy } from "../../../service/checkExistsBy";
import { useI18n } from "../../../translation";
import { useTranslationHelpers } from "../../../translation/useTranslationHelpers";
import { ExerciseLists } from "../components/ExerciseLists/ExerciseLists";
import { useExerciseListData } from "../components/ExerciseLists/useExerciseListData";

export const EditWorkoutData = () => {
  const i18n = useI18n();
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const defaultValues = useLoaderData() as Workout | undefined;
  const revalidator = useRevalidator();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    clearErrors,
    reset,
  } = useForm<Workout>({
    reValidateMode: "onSubmit",
    defaultValues,
  });

  const {
    selectedExercises,
    setSelectedExercises,
    availableExercises,
    setAvailableExercises,
  } = useExerciseListData(defaultValues?.exercises);

  const isDirtyExercises =
    // new exercises added
    defaultValues?.exercises.length !== selectedExercises?.length ||
    // old exercises removed or moved
    defaultValues?.exercises.some(
      (initial, initialIndex) =>
        selectedExercises?.[initialIndex].id !== initial.exerciseId
    );

  const updateWorkout = async (data: Workout) => {
    const newData: Workout = {
      ...data,
      name: data.name.trim(),
      exercises: (selectedExercises || []).map((exercise, index) => ({
        exerciseId: exercise.id,
        order: index,
      })),
    };
    update(ref(firebaseDB, `workouts/${data.id}`), {
      ...newData,
      updatedOn: new Date().toISOString(),
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit: SubmitHandler<Workout> = async (data) => {
    setIsLoading(true);
    try {
      const alreadyExists = await checkExistsBy({
        prop: "name",
        path: "workouts",
        value: data.name.trim(),
      });
      const isNameChanged = defaultValues?.name.trim() !== data.name.trim();
      if (isNameChanged && alreadyExists) {
        toast.error(i18n["error.workout.alreadyExists"]);
        return undefined;
      }
      await updateWorkout(data);
      toast.success(i18n["workout.success.updated"]);
      revalidator.revalidate();
      reset(data);
    } catch (error) {
      toast.error(getTranslatedErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => clearErrors()}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        p={2}
        pl={3}
        rowGap={4}
        component={Paper}
        elevation={2}
        height="100%"
      >
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          {i18n["form.edit.subtitle"]}
        </Typography>

        <TextField
          sx={{ width: "50%" }}
          required
          label={i18n["name"]}
          {...register("name", { required: true })}
        />
        <TextField
          sx={{ width: "50%" }}
          label={i18n["description"]}
          multiline
          {...register("description")}
        />
        <Box display="flex" height={400}>
          <ExerciseLists
            selectedExercises={selectedExercises}
            setSelectedExercises={setSelectedExercises}
            availableExercises={availableExercises}
            setAvailableExercises={setAvailableExercises}
          />
        </Box>

        <LoadingButton
          sx={{ alignSelf: "flex-start" }}
          type="submit"
          loading={isLoading}
          disabled={
            (!isDirty && !isDirtyExercises) ||
            (selectedExercises || []).length < 1
          }
        >
          {i18n["form.buttons.save"]}
        </LoadingButton>
      </Box>
    </Box>
  );
};
