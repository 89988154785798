import { LoadingButton } from '@mui/lab'
import { Box, Paper, TextField, Typography } from '@mui/material'
import { User } from '@peakconcepts/physio-types/User'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useI18n } from '../../../translation'
import { useTranslationHelpers } from '../../../translation/useTranslationHelpers'
import { UserTypeSelection } from '../components/UserTypeSelection'

export const EditUserData = () => {
  const i18n = useI18n()
  const { getTranslatedErrorMessage } = useTranslationHelpers()
  const defaultValues = useLoaderData() as User | undefined
  const revalidator = useRevalidator()

  const formMethods = useForm<User>({
    reValidateMode: 'onSubmit',
    defaultValues,
  })
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    clearErrors,
    reset,
  } = formMethods

  const functions = getFunctions()
  const updateUser = httpsCallable(functions, 'updateUser')
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit: SubmitHandler<User> = data => {
    setIsLoading(true)
    updateUser(data)
      .then(response => {
        toast.success(i18n['user.success.updated'])
        revalidator.revalidate()
        reset(data)
      })
      .catch(error => toast.error(getTranslatedErrorMessage(error)))
      .finally(() => setIsLoading(false))
  }

  return (
    <FormProvider {...formMethods}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => clearErrors()}
        method="">
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          p={2}
          pl={3}
          rowGap={4}
          component={Paper}
          elevation={2}>
          <Typography color="primary" sx={{ fontWeight: 'bold' }}>
            {i18n['form.edit.subtitle']}
          </Typography>
          <TextField
            required
            fullWidth
            label={i18n['user.firstName']}
            {...register('firstName', { required: true })}
          />
          <TextField
            required
            fullWidth
            label={i18n['user.lastName']}
            {...register('lastName', { required: true })}
          />
          <TextField
            fullWidth
            label={i18n['user.city']}
            {...register('location.city')}
          />
          <UserTypeSelection />

          <LoadingButton
            sx={{ alignSelf: 'flex-start' }}
            type="submit"
            disabled={!isDirty}
            loading={isLoading}>
            {i18n['form.buttons.save']}
          </LoadingButton>
        </Box>
      </form>
    </FormProvider>
  )
}
