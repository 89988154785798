import { Box } from "@mui/material";
import { get, ref } from "firebase/database";
import { Params } from "react-router-dom";
import { HomeHeader } from "../../../components/HomeHeader";
import { firebaseDB } from "../../../config/firebase";
import { useI18n } from "../../../translation";
import { EditUserData } from "./EditUserData";
import { EditUserLogin } from "./EditUserLogin";
import { UserInfoPaper } from "./UserInfoPaper";

export const EditUser = () => {
  const i18n = useI18n();

  return (
    <>
      <HomeHeader title={i18n["user.edit.title"]} />
      <Box display="flex" gap={4}>
        <Box display="flex" flexDirection="column" gap={4} flex={1}>
          <EditUserData />
          <EditUserLogin />
        </Box>
        <Box display="flex" flexDirection="column" gap={4} flex={1}>
          <UserInfoPaper />
        </Box>
      </Box>
    </>
  );
};

export const userLoader = async ({ params }: { params: Params }) => {
  const { userId } = params;
  const isNewEntry = userId === "new";
  if (isNewEntry) return undefined;

  const dbRef = ref(firebaseDB, `users/${userId}`);
  return get(dbRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return { ...snapshot.val(), id: userId };
      }
    })
    .catch((error) => {
      console.error(error);
      throw new Response("", { status: 404 });
    });
};
