import { Box, Paper, Typography } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { useI18n } from "../../../translation";
import { useDateHelpers } from "../../../utils/useLocalDate";
import { CustomTrainingPlan } from "../NewTrainingPlan/NewTrainingPlan";

export const TrainingPlanInfoPaper = () => {
  const i18n = useI18n();
  const { getLocalDate } = useDateHelpers();
  const defaultValues = useLoaderData() as CustomTrainingPlan | undefined;

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      pl={3}
      rowGap={4}
      component={Paper}
      elevation={2}
    >
      <Typography color="primary" sx={{ fontWeight: "bold" }}>
        {i18n["form.additionalInfo.subtitle."]}
      </Typography>
      <Box>
        <Typography variant="caption">{i18n["user.id"]}</Typography>
        <Typography>{defaultValues?.userId}</Typography>
      </Box>
      <Box display="flex" flex={1} gap={10}>
        <Box>
          <Typography variant="caption">{i18n["createdOn"]}</Typography>
          <Typography>{getLocalDate(defaultValues?.createdOn)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
