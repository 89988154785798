import { DateTime, Interval } from "luxon";
import { DayOfWeekShort } from "../../utils/types/DayOfWeek";
import { CustomTrainingPlan } from "./NewTrainingPlan/NewTrainingPlan";
import { UserWorkout } from "@peakconcepts/physio-types/UserWorkout";

const getWeekday = (dayNumber: number) => {
  const allDays: Record<number, DayOfWeekShort> = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun",
  };
  return allDays[dayNumber];
};

export const generateUserWorkouts = (data: CustomTrainingPlan) => {
  // create array with unique days from workouts
  const workoutDays = [
    ...new Set(data.workouts.map((workout) => workout.dayOfWeek)),
  ];
  const startDate = DateTime.fromISO(data.startDate);
  const endDate = DateTime.fromISO(data.endDate);
  const interval = Interval.fromDateTimes(startDate, endDate.plus({ days: 1 }));
  // get all dates from startDate to endDate
  const allDates = interval.splitBy({ days: 1 }).map((d) => {
    return { date: d.start, dayOfWeek: getWeekday(d.start.weekday) };
  });
  // keep only days where a workout should be done
  const relevantDates = allDates.filter(({ dayOfWeek }) =>
    workoutDays.includes(dayOfWeek)
  );

  let userWorkouts: UserWorkout[] = [];
  relevantDates.forEach((relevantDate) => {
    data.workouts.forEach((workout, index) => {
      if (workout.dayOfWeek === relevantDate.dayOfWeek) {
        userWorkouts.push({
          id: `${index}`,
          userId: data.userId,
          workoutId: workout.id,
          dayOfWeek: workout.dayOfWeek,
          scheduledFor: relevantDate.date.toISODate(),
        });
      }
    });
  });

  return userWorkouts;
};
