import { Box } from "@mui/material";
import { TrainingPlan } from "@peakconcepts/physio-types/TrainingPlan";
import { get, ref } from "firebase/database";
import { Params } from "react-router-dom";
import { HomeHeader } from "../../../components/HomeHeader";
import { firebaseDB } from "../../../config/firebase";
import { useI18n } from "../../../translation";
import { EditTrainingPlanData } from "./EditTrainingPlanData";
import { TrainingPlanInfoPaper } from "./TrainingPlanInfoPaper";
import { UserWorkoutsInfo } from "./UserWorkoutsInfo";

export const EditTrainingPlan = () => {
  const i18n = useI18n();

  return (
    <>
      <HomeHeader title={i18n["trainingPlan.edit.title"]} />
      <Box display="flex" sx={{ flexDirection: "column" }} gap={4}>
        <Box
          display="flex"
          sx={{ flexDirection: { xs: "column", lg: "row" } }}
          gap={4}
        >
          <Box display="flex" flexDirection="column" gap={4} flex={1}>
            <EditTrainingPlanData />
          </Box>
          <Box display="flex" flexDirection="column" gap={4} flex={1}>
            <TrainingPlanInfoPaper />
          </Box>
        </Box>
        <UserWorkoutsInfo />
      </Box>
    </>
  );
};

export const trainingPlanLoader = async ({ params }: { params: Params }) => {
  const { userId } = params;
  try {
    const trainingPlanSnapshot = await get(
      ref(firebaseDB, `trainingPlans/${userId}`)
    );
    if (!trainingPlanSnapshot.exists()) return null;
    const { workouts: trainingPlanWorkouts, ...otherTrainingPlanData } =
      trainingPlanSnapshot.val() as TrainingPlan;
    // get full workout data by id
    const workouts = await Promise.all(
      trainingPlanWorkouts.map(({ workoutId, dayOfWeek }) =>
        get(ref(firebaseDB, `workouts/${workoutId}`)).then(
          (snapshot) => snapshot.exists() && { ...snapshot.val(), dayOfWeek }
        )
      )
    );
    // workouts which couldn't be found by id, get filtered out
    return { ...otherTrainingPlanData, workouts: workouts.filter(Boolean) };
  } catch (error) {
    console.error(error);
    throw new Response("", { status: 404 });
  }
};
