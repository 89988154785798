export const DE_DE = {
  appName: "MediHome Admin",
  signIn: "Einloggen",
  email: "E-Mail",
  newPassword: "Neues Passwort",
  password: "Passwort",
  rememberMe: "Angemeldet bleiben",
  forgotPassword: "Passwort vergessen?",
  logout: "Logout",
  defaultUserName: "Administrator",
  allowed: "Erlaubt",
  notAllowed: "Nicht erlaubt",
  unknown: "Unbekannt",
  name: "Name",
  description: "Beschreibung",
  createdOn: "Erstellt am",
  updatedOn: "Aktualisiert am",
  order: "Reihenfolge",
  choose: "Auswählen",
  add: "Hinzufügen",

  dayOfWeek: "Wochentag",
  "dayOfWeek.long.Mon": "Montag",
  "dayOfWeek.long.Tue": "Dienstag",
  "dayOfWeek.long.Wed": "Mittwoch",
  "dayOfWeek.long.Thu": "Donnerstag",
  "dayOfWeek.long.Fri": "Freitag",
  "dayOfWeek.long.Sat": "Samstag",
  "dayOfWeek.long.Sun": "Sonntag",

  "drawer.title": "Verwaltung",
  "drawer.users": "Nutzer",
  "drawer.exercises": "Übungen",
  "drawer.videos": "Videos",
  "drawer.workouts": "Trainingseinheiten",
  "drawer.trainingPlans": "Trainingspläne",
  "drawer.userWorkouts": "Kunden-Trainingseinheiten",

  "start.welcomeBack": "Willkommen zurück!",
  "start.pleaseChooseEntry": "Bitte wähle einen Eintrag aus dem Menü.",

  "grid.actions": "Aktionen",
  "grid.addEntry": "Eintrag hinzufügen",

  "form.buttons.save": "Speichern",
  "form.buttons.cancel": "Abbrechen",
  "form.new.subtitle": "Daten anlegen",
  "form.edit.subtitle": "Daten bearbeiten",
  "form.view.subtitle": "Daten ansehen",
  "form.additionalInfo.subtitle.": "Zusätzliche Informationen",

  "user.id": "Nutzer-ID",
  "user.lastLoginOn": "Letzter Login am",
  "user.firstName": "Vorname",
  "user.lastName": "Nachname",
  "user.city": "Stadt",
  "user.type": "Typ",
  "user.type.standard": "Standard",
  "user.type.private": "Privat",
  "user.new.title": "Nutzer anlegen",
  "user.edit.title": "Nutzer bearbeiten",
  "user.subtitle.editPassword": "Passwort bearbeiten",
  "user.subtitle.editLoginData": "Anmeldedaten bearbeiten",
  "user.success.created": "Nutzer wurde erfolgreich erstellt",
  "user.success.updated": "Nutzer wurde erfolgreich aktualisiert",
  "user.success.deleted": "Nutzer wurde erfolgreich gelöscht",

  exercise: "Übung",
  "exercise.id": "Übungs-ID",
  "exercise.category": "Kategorie",
  "exercise.videoUrl": "Video-URL",
  "exercise.new.title": "Übung anlegen",
  "exercise.edit.title": "Übung bearbeiten",
  "exercise.video": "Video",
  "exercise.success.created": "Übung wurde erfolgreich erstellt",
  "exercise.success.updated": "Übung wurde erfolgreich aktualisiert",
  "exercise.success.deleted": "Übung wurde erfolgreich gelöscht",

  workout: "Trainingseinheit",
  "workout.id": "Trainingseinheit-ID",
  "workout.exerciseCount": "Anzahl Übungen",
  "workout.new.title": "Trainingseinheit anlegen",
  "workout.edit.title": "Trainingseinheit bearbeiten",
  "workout.success.created": "Trainingseinheit wurde erfolgreich erstellt",
  "workout.success.updated": "Trainingseinheit wurde erfolgreich aktualisiert",
  "workout.success.deleted": "Trainingseinheit wurde erfolgreich gelöscht",
  "workout.exerciseList.available": "Verfügbare Übungen",
  "workout.exerciseList.selected": "Hinzugefügte Übungen",

  trainingPlan: "Trainingsplan",
  "trainingPlan.id": "Trainingsplan-ID",
  "trainingPlan.startDate": "Startdatum",
  "trainingPlan.endDate": "Enddatum",
  "trainingPlan.new.title": "Trainingsplan anlegen",
  "trainingPlan.edit.title": "Trainingsplan bearbeiten",
  "trainingPlan.success.created": "Trainingsplan wurde erfolgreich erstellt",
  "trainingPlan.success.updated":
    "Trainingsplan wurde erfolgreich aktualisiert",
  "trainingPlan.success.deleted": "Trainingsplan wurde erfolgreich gelöscht",
  "trainingPlan.select.user": "Nutzer auswählen",
  "trainingPlan.selected": "Ausgewählte Trainingseinheiten",
  "trainingPlan.workouts.scheduledFor": "Geplant für",
  "trainingPlan.workouts.completedOn": "Absolviert am",
  "trainingPlan.workouts.feedback": "Feedback",
  "trainingPlan.workouts.feedback.hard": "Zu schwer",
  "trainingPlan.workouts.feedback.perfect": "War perfekt",
  "trainingPlan.workouts.feedback.easy": "Zu einfach",

  "error.unknown": "Es ist ein Fehler aufgetreten",
  "error.unknownValue": "Fehlerhafter Wert",
  "error.form.endDate.minDate": "Enddatum liegt vor dem Startdatum",
  "error.form.email.required": "Keine E-Mail angegeben",
  "error.form.email.invalid": "Ungültige E-Mail",
  "error.form.password.minLength":
    "Passwort muss mindestens 8 Zeichen beinhalten",
  "error.login.failed": "Falsche E-Mail oder falsches Passwort",
  "error.exercise.alreadyExists": "Übung mit gleichem Namen existiert bereits",
  "error.workout.alreadyExists":
    "Trainingseinheit mit gleichem Namen existiert bereits",
  "error.trainingPlan.alreadyExists":
    "Trainingsplan für diese E-Mail existiert bereits",
  "error.trainingPlan.noUser": "Kein Nutzer ausgewählt",

  "firebase.error.auth.email-already-exists":
    "Die angegebene E-Mail-Adresse wird bereits verwendet",
  "firebase.error.auth.invalid-email": "Die eingegebene Email ist ungültig",
  "firebase.error.auth.invalid-password":
    "Das angegebene Passwort ist ungültig",
  "firebase.error.auth.user-not-found":
    "Benutzerdatensatz wurde nicht gefunden",
  "firebase.error.not-found": "Der angeforderte Datensatz wurde nicht gefunden",
  "firebase.error.already-exists":
    "Der zu erstellende Datensatz existiert bereits",
  "firebase.error.resource-exhausted":
    "Zu viele Anfragen oder zu wenig Speicherplatz übrig",
  "firebase.error.unavailable": "Der Dienst ist derzeit nicht verfügbar",
  "firebase.error.unauthenticated": "Zugriff verweigert. Nicht authentifiziert",
};
