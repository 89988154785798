import {
  DataGrid,
  GridActionsCellItem,
  deDE,
  GridColumns,
  GridRowsProp,
} from "@mui/x-data-grid";
import { useI18n } from "../../translation";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocale } from "../../service/LocaleProvider";
import { CustomToolbar } from "../../components/grid/CustomToolbar";
import { useNavigate } from "react-router-dom";
import { get, onValue, ref, remove } from "firebase/database";
import { firebaseDB } from "../../config/firebase";
import { useEffect, useState } from "react";
import { TrainingPlan } from "@peakconcepts/physio-types/TrainingPlan";
import { HomeHeader } from "../../components/HomeHeader";
import { toast } from "react-toastify";
import { useTranslationHelpers } from "../../translation/useTranslationHelpers";
import { useDateHelpers } from "../../utils/useLocalDate";

export const TrainingPlans = () => {
  const i18n = useI18n();
  const { getTranslatedErrorMessage } = useTranslationHelpers();
  const { getLocalDate } = useDateHelpers();
  const userLocale = useLocale();
  const navigate = useNavigate();

  const [rows, setRows] = useState<
    GridRowsProp<TrainingPlan & { userEmail: string }>
  >([]);
  useEffect(() => {
    const dbRef = ref(firebaseDB, "trainingPlans");
    return onValue(
      dbRef,
      (snapshot) => {
        if (snapshot.exists()) {
          // plans have only the userId
          const trainingPlans = Object.values(snapshot.val()) as TrainingPlan[];
          // get users for the userEmail
          Promise.all(
            trainingPlans.map((plan) =>
              get(ref(firebaseDB, `users/${plan.userId}`))
            )
          ).then((snapshots) => {
            const users = snapshots
              .filter((snapshot) => snapshot.exists())
              .map((snapshot) => snapshot.val());
            // { id: mail, id2: mail2, ...}
            const userEmails = Object.fromEntries(
              users.map(({ id, email }) => [id, email])
            );
            const newRows = trainingPlans.map((plan) => ({
              ...plan,
              userEmail: userEmails[plan.userId],
            }));
            setRows(newRows);
          });
        } else setRows([]);
      },
      (error) => toast.error(getTranslatedErrorMessage(error))
    );
  }, []);

  const localeText =
    userLocale === "de-DE"
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : undefined;

  const onAddNewEntry = () => navigate("/trainingPlans/new");

  const [isLoading, setIsLoading] = useState(false);
  const onDeleteEntry = async (userId: string) => {
    setIsLoading(true);
    try {
      await remove(ref(firebaseDB, `trainingPlans/${userId}`));
      await remove(ref(firebaseDB, `userWorkouts/${userId}`));
      toast.success(i18n["trainingPlan.success.deleted"]);
    } catch (error) {
      toast.error(getTranslatedErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const columns: GridColumns = [
    { field: "userEmail", headerName: i18n["email"], flex: 1 },
    {
      field: "startDate",
      headerName: i18n["trainingPlan.startDate"],
      valueGetter: ({ row }) =>
        getLocalDate(row?.startDate, { onlyDate: true }),
      flex: 1,
    },
    {
      field: "endDate",
      headerName: i18n["trainingPlan.endDate"],
      valueGetter: ({ row }) => getLocalDate(row?.endDate, { onlyDate: true }),
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: i18n["grid.actions"],
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(`/trainingPlans/${row.userId}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => onDeleteEntry(row.userId)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <HomeHeader title={i18n["drawer.trainingPlans"]} />
      <DataGrid
        sx={{
          // disable last column seperator
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
        getRowId={(row) => row.userId}
        rows={rows}
        columns={columns}
        localeText={localeText}
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: { onAddNewEntry },
        }}
        loading={isLoading}
      />
    </>
  );
};
